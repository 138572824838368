import { types, flow } from 'mobx-state-tree';

import { sortBy, groupBy, uniq } from 'lodash';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { DeviceHistory } from 'models/types';
import { Gateway } from 'models/types';
import { Alarm } from 'models/types';
import { numberUtilities, stringUtilities, dateUtilities } from 'utils';

const colors = [
  '#708090',
  '#FF7A00',
  '#800000',
  '#F44336',
  '#0BC207',
  '#FF1493',
  '#FFCE44',
  '#13A5BC',
  '#9467BD',
  '#8C564B',
  '#FFCE44',
  '#13A5BC',
];
const captions = [
  'Cooler',
  'Glycol Pump #4',
  'Glycol Pump #3',
  'Glycol Pump #2',
  'Glycol Pump #1',
  'Glycol Bath',
  'Ambient',
  'Compressor',
  'Tower #1',
  'Tower #2',
  'Humidity',
  'Temperature',
];

const names_map = {
  bru_hum: 'Humidity',
  bru_temp_c: 'Temperature',
  bru_baro: 'Pressure',
};

export const deviceHistoryInitialState = {
  isLoaded: false,
  gateways: [],
  selectedGateways: [],
  selectedGatewayId: null,
  dataset: [],
  disabledDatasets: [],
  period: {
    from: null,
    to: null,
  },
  state: 'done',
  updated: null,
  alarms: [],
  hiddenDatasetKeys: [],
  activeTab: 'equipment',
};

export const deviceHistoryModel = types
  .model({
    isLoaded: types.boolean,
    gateways: types.array(Gateway),
    selectedGateways: types.array(types.integer),
    selectedGatewayId: types.maybeNull(types.integer),
    dataset: types.array(DeviceHistory),
    disabledDatasets: types.array(types.string),
    period: types.model({
      from: types.maybeNull(types.string),
      to: types.maybeNull(types.string),
    }),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    alarms: types.array(Alarm),
    hiddenDatasetKeys: types.array(types.string),
    activeTab: types.enumeration('activeTab', ['equipment', 'alerts']),
  })
  .views(self => ({
    get items() {
      if (self.dataset.length) {
        const gateway = self.activeGateway;
        const labels = Array.from(self.dataset).map(item => item.bin_from);
        if (gateway?.type_id === 0) {
          const datasetEntryWithBruSummary = self.dataset.find(entry => entry.bru_summary !== null);
          const names = Object.keys(datasetEntryWithBruSummary.bru_summary || {});
          const dataset = names.map((name, index) => {
            const data = self.dataset.map(data => {
              const mean = data.bru_summary?.[name]?.mean;
              if (name === 'bru_temp_c') {
                return numberUtilities.convertTemperature(mean || null);
              } else {
                return mean?.toFixed(1) || null;
              }
            });
            const formattedName = names_map[name];
            const colorIndex = captions.findIndex(caption => formattedName.includes(caption));
            return {
              active: !self.hiddenDatasetKeys.includes(formattedName),
              key: formattedName,
              color: colors[colorIndex],
              values: data,
            };
          });
          return {
            labels: labels,
            names: names,
            dataset: dataset,
          };
        } else {
          const valid_dataset = self.dataset.find(data => data.connected_sensors !== null);
          const names = Array.from(valid_dataset?.connected_sensors?.keys() || []);
          const dataset = names.map((name, index) => {
            const [formattedName] = name.split(' (');
            const colorIndex = captions.findIndex(caption => name.includes(caption));
            const data = self.dataset.map(data => {
              const temperature =
                stringUtilities
                  .makeTemperature(data.connected_sensors?.get(name)?.mean)
                  ?.replace(/(°F|°C)/, '') || null;
              return formattedName.key === 'Humidity'
                ? data.connected_sensors?.get(name)?.mean
                : temperature;
            });

            return {
              active: !self.hiddenDatasetKeys.includes(formattedName),
              key: formattedName,
              color: colors[colorIndex],
              values: data,
            };
          });

          return {
            labels: labels,
            names: names,
            dataset: dataset,
          };
        }
      }

      return {
        labels: [],
        names: [],
        dataset: [],
      };
    },

    get disabledItems() {
      return self.disabledDatasets;
    },

    get activeGateway() {
      return self.gateways.find(({ id }) => id === self.selectedGatewayId);
    },

    get lines() {
      const root = getRootStore();
      return root.linesStatisticsStore?.sortedLinesById(self.activeGateway?.cooler_id);
    },

    get sortedGateways() {
      const grouped = groupBy(
        self.gateways.filter(e => Boolean(e.cooler_id)),
        'cooler_id',
      );

      const result = [];
      Object.values(grouped).forEach(items => {
        if (items.length > 1) {
          const filteredTraditional = items.filter(item => item.type_id === 0);
          result.push(filteredTraditional[0]);

          const filteredNonTraditional = items.filter(item => item.type_id !== 0);
          result.push(...filteredNonTraditional);
        } else {
          result.push(...items);
        }
      });

      return sortBy(result, '_coolers_name').filter(Boolean);
    },

    get extendedGateways() {
      return self.gateways.map(gateway => {
        const currentGatewaysAlarms = self.alarms.filter(
          alarm => alarm._gateways_id === gateway.id,
        );
        let alarms = [];
        let recipients = [];

        if (currentGatewaysAlarms.length) {
          const grouped = groupBy(currentGatewaysAlarms, 'sensor_id');
          alarms = Object.keys(grouped).map(key => ({
            low: grouped[key].find(e => e.rule.includes('<')),
            high: grouped[key].find(e => e.rule.includes('>')),
            name: grouped[key].map(e => e.original_sensor_name).filter(e => e)[0],
            sensor_name: `Sensor #${grouped[key][0].sensor_id}`,
          }));

          alarms = alarms.map(alarm => ({
            ...alarm,
            min: numberUtilities.celsiusToFahrenheit(
              stringUtilities.sliceNumber(alarm.low?.rule, '<', ')'),
              true,
            ),
            max: numberUtilities.celsiusToFahrenheit(
              stringUtilities.sliceNumber(alarm.high?.rule, '>', ')'),
              true,
            ),
            default_min: numberUtilities.celsiusToFahrenheit(
              stringUtilities.sliceNumber(alarm.low?.default_rule, '<', ')'),
              true,
            ),
            default_max: numberUtilities.celsiusToFahrenheit(
              stringUtilities.sliceNumber(alarm.high?.default_rule, '>', ')'),
              true,
            ),
          }));

          recipients = uniq(
            currentGatewaysAlarms.map(({ role_users_ids }) => role_users_ids || []).flat(),
          );
        }

        return {
          ...gateway,
          alarms,
          alarmsAmount: currentGatewaysAlarms.length,
          recipients,
          muted: gateway.id % 2 === 0, // temporary stub
        };
      });
    },

    get gatewaysWithAlarms() {
      const root = getRootStore();
      return self.extendedGateways
        .filter(gw => gw.cooler_id === root.coolersStore.selectedCooler?.id)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    get alarmsForCurrentUser() {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;

      return self.gatewaysWithAlarms.filter(({ recipients }) => recipients.includes(roleUserId));
    },
    get subscribedAlarmsIds() {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;
      return self.alarms
        .filter(({ role_users_ids }) => role_users_ids?.includes(roleUserId))
        .map(({ id }) => id);
    },

    get bins() {
      // Used for API and calculation number of X axis chart labels
      return 200;
    },
  }))
  .actions(self => ({
    fetchIdentifiers: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getGetaways();

        if (response?.data?.result) {
          self.gateways.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    fetchDataset: flow(function* () {
      try {
        if (!self.activeGateway?.cooler_id) return;

        self.state = 'pending';

        let { from, to } = self.period;

        if (!from || !to) {
          ({ from, to } = dateUtilities.getDatesByPeriod('1'));
        }

        const body = {
          from_ts: from,
          to_ts: to,
          bins: self.bins,
        };

        const gateway = self.activeGateway;
        let response = null;
        if (gateway.type_id === 0) {
          const params = {
            id: gateway.cooler_id,
          };
          response = yield api.getCoolerBruSummary(body, params);
        } else {
          const params = {
            identifier: gateway.identifier,
          };
          response = yield api.getDeviceHistory(body, params);
        }

        if (response?.data?.result) {
          self.dataset.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    subscribeToAlerts: flow(function* (body, params) {
      try {
        const response = yield api.subscribeToAlerts(body, params);

        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms.replace([
          ...self.alarms.filter(({ id }) => !updatedIds.includes(id)),
          ...updatedAlarms,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    unsubscribeFromAlerts: flow(function* (body, params) {
      try {
        const response = yield api.unsubscribeFromAlerts(body, params);

        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms.replace([
          ...self.alarms.filter(({ id }) => !updatedIds.includes(id)),
          ...updatedAlarms,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    patchAlert: flow(function* (body, params) {
      try {
        const response = yield api.patchAlerts(body, params);

        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms.replace([
          ...self.alarms.filter(({ id }) => !updatedIds.includes(id)),
          ...updatedAlarms,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    disableAlarms: flow(function* () {
      try {
        const response = yield api.disableAlarms();
        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms.replace(
          self.alarms.map(alarm => {
            return updatedIds.includes(alarm.id)
              ? {
                  ...alarm,
                  role_users_ids: updatedAlarms.find(a => a.id === alarm.id)?.role_users_ids,
                }
              : alarm;
          }),
        );
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setPeriod(period) {
      self.period = period;
    },

    updateDisabledDataset(key) {
      if (self.disabledDatasets.includes(key)) {
        self.disabledDatasets.replace(self.disabledDatasets.filter(item => item !== key));
      } else {
        self.disabledDatasets.push(key);
      }
    },

    setSelectedGatewayId(id) {
      self.selectedGatewayId = id;
      self.fetchDataset();
    },

    setDeviceHistory(rows) {
      self.gateways.replace(rows);
    },

    setAlarms(rows) {
      // Handle case of multiple alarms of the same type for specific sensor
      // Remove after fixing duplicate alarms in db
      const uniq = Object.entries(
        groupBy(
          rows.filter(e => !!e.prototype_alarm_id),
          'sensor_id',
        ),
      ).flatMap(([sensor_id, alarms]) => alarms.sort((a, b) => b.id - a.id).slice(0, 2));

      self.alarms.replace(uniq);
    },

    setActiveTab(tab) {
      if (['equipment', 'alerts'].includes(tab)) self.activeTab = tab;
    },

    changeVisibility(key) {
      if (self.hiddenDatasetKeys.includes(key)) {
        self.hiddenDatasetKeys = self.hiddenDatasetKeys.filter(e => e !== key);
      } else {
        self.hiddenDatasetKeys = [...self.hiddenDatasetKeys, key];
      }
    },
  }));
